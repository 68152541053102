<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
  .card {
    padding: 0.6em;
    opacity: 0.85;
    color: black;
    font-size: 20px;
  }
  .card {
    @media (min-width: 640px) { font-size: 24px; }
    @media (min-width: 768px) { font-size: 25px; }
    @media (min-width: 1024px) { font-size: 26px; }
    @media (min-width: 1280px) { font-size: 27px; }
    @media (min-width: 1536px) { font-size: 28px; }
    @media (min-width: 2000px) { font-size: 30px; }
    @media (min-width: 2400px) { font-size: 33px; }
    @media (min-width: 2800px) { font-size: 36px; }
    @media (min-width: 3200px) { font-size: 39px; }
    @media (min-width: 3600px) { font-size: 43px; }
    @media (min-width: 4000px) { font-size: 46px; }
  }
</style>