import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './routes';
import SessaoServiceTelao from '../domain/SessaoServiceTelao';

Vue.use(VueRouter);

const router = new VueRouter({
    routes,
    mode: 'history'
});

router.beforeEach((to, from, next) => {
    let dominio = to.params.dominio;

    Vue.prototype.$dominio = dominio;

    let sessaoServiceTelao = new SessaoServiceTelao(dominio);

    let sessionIdStorage = sessionStorage.getItem('sessaoId');

    if (sessionIdStorage) {
        Vue.prototype.$sessaoId = sessionStorage.getItem('sessaoId');
        next();
    } else {
        sessaoServiceTelao
            .next()
            .then(response => {
                sessionStorage.setItem('sessaoId', response.data.sessaoId);
                Vue.prototype.$sessaoId = response.data.sessaoId;
                next();
            })
            .catch(err => err);
    }
});

export default router;
