import http from '../http/http-telao';

export default class SessaoService {
    constructor(dominio) {
        this._dominio = dominio;
    }

    /**
     * Retorna o ID da Sessão definida como próxima e com data de ocorrência mais antiga.
     * Caso não exista, retorna 404.
     */
    next() {
        return http.get(`${this._dominio}/painelEletronico`);
    }

    /**
     * Retorna a Sessão definida como próxima e com data de ocorrência mais antiga.
     * Caso não exista, retorna 404.
     */
    proximaSessao() {
        return http.get(`${this._dominio}/proxima-sessao`);
    }
    
    /**
     * Retorna a Sessão definida como aberta.
     * Caso não exista, retorna 404.
     */
    sessaoAbertaAtual() {
        return http.get(`${this._dominio}/sessao-aberta-atual`);
    }
    
    /**
     * Retorna os dados da sessão, proposição do dia e expediente, se a votação esta aberta ou aguardando presidente
     *
     * @param {int} id
     */
    show(id) {
        return http.get(`/${this._dominio}/sessao/${id}`);
    }

    /**
     * Retorna os dados somente da sessão
     *
     * @param {int} id
     */
    showSessao(id) {
        return http.get(`/${this._dominio}/sessaoV2/${id}`);
    }

    /**
     * Retorna os dados da sessão somento com as proposições
     *
     * @param {int} id
     */
    showSessaoProposicao(id) {
        return http.get(`/${this._dominio}/sessaoProposicao/${id}`);
    }

    /**
     * Retorna os dados do cliente
     */
    getDadosBasicos() {
        return http.get(`/${this._dominio}/dados-cliente`);
    }

    /**
     * Retorna os dados do cliente
     */
    getTelaoSettings() {
        return http.get(`/${this._dominio}/telao-settings`);
    }

    /**
     * Retorna os dados da proposição atual
     *
     * @param {int} sessaoId
     */
    getCurrentProposicaoArquivo(sessaoId) {
        return http.get(`/${this._dominio}/sessao/${sessaoId}/proposicao/current`);
    }

    /**
     * Retorna os os dados do vereador que esta com a palavra
     *
     * @param {int} id
     */
    getLastGrantedWord(id) {
        return http.get(`/${this._dominio}/sessao/${id}/vereador/palavra`);
    }
}
