<template>
  <div class="topo">
    <div class="caixa-brasao">
      <LoadingSpinnerBlack :is-visible="urlBrasao.length == 0" />
      <img v-show="urlBrasao.length > 0" :src="urlBrasao" alt="Brasão">
    </div>
    <div class="caixa-titulo">{{textoTituloSessao}}</div>
    <div class="caixa-indicador" @click.stop="triggerFullscreenMode">
      <i class="fas fa-tv mr-1"></i>&nbsp;<span>{{textoTelaAtual}}</span>
    </div>
  </div>
</template>

<script>
import LoadingSpinnerBlack from './LoadingSpinnerBlack.vue'
//import SessaoServiceTelao from '../../domain/SessaoServiceTelao';

export default {
  components: {
    LoadingSpinnerBlack
  },

  props: {
    urlBrasao: {
      type: String,
      required: true
    },
    telaAtual: {
      type: String,
      required: true
    },
    tituloSessao: {
      type: String,
      required: true
    },
  },

  computed: {
    textoTelaAtual() {
      let telas = {
        'painelEletronico': 'Tela principal',
        'votacao': 'Votações',
        'comPalavra': 'Com a Palavra',
        'presentes': 'Vereadores Presentes',
      };
      if (this.telaAtual.length > 0 && telas.hasOwnProperty(this.telaAtual)) {
        return telas[this.telaAtual];
      }
      return 'Tela sem nome';
    },
    textoTituloSessao() {
      if (!this.tituloSessao || this.tituloSessao.length == 0) return '[sem título]';
      return this.tituloSessao;
    }
  },

  methods: {
    triggerFullscreenMode() {
      document.documentElement.webkitRequestFullScreen();
    }
  },

}
</script>

<style lang="scss" scoped>
  .topo {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    padding: 15px 20px 8px 20px;
    font-size: 10px;
    background-color: rgba(255,255,255,1);
    color: rgba(0,0,0,0.8);
    border-bottom: 1pt solid rgba(0,0,0,0.5);
  }

  .caixa-brasao > img {
    max-width: 5em;
    max-height: 3em;
  }

  .caixa-indicador {
    font-size: 1em;
    line-height: 1;
    padding: 0.6em 1em;
    border: 2pt solid rgba(0,0,0,0.4);
    border-radius: 1.5em;
    background-color: rgba(0,0,0,0);
  }

  .caixa-titulo {
    flex-grow: 1;
    text-align: center;
    font-weight: bold;
    font-size: 1.8em;
  }

  .topo {
    @media (min-width: 640px) { font-size: 13px; }
    @media (min-width: 768px) { font-size: 14px; }
    @media (min-width: 1024px) { font-size: 15px; }
    @media (min-width: 1280px) { font-size: 16px; }
    @media (min-width: 1536px) { font-size: 17px; }
    @media (min-width: 2000px) { font-size: 18px; }
    @media (min-width: 2400px) { font-size: 20px; }
    @media (min-width: 2800px) { font-size: 23px; }
    @media (min-width: 3200px) { font-size: 26px; }
    @media (min-width: 3600px) { font-size: 29px; }
    @media (min-width: 4000px) { font-size: 32px; }
  }

</style>