<template>
    <section class="main-section">
        <div>
            <barra-topo
                :url-brasao="brasao"
                :titulo-sessao="tituloSessao || nomeCliente"
                :tela-atual="currentRouteName"
            />
        </div>
        <div class="conteudo" :class="{ standby: isStandByMode }">
            <div v-if="existeSessaoAtual && !isEncerradaRecente" class="caixa-view">
                <transition name="fadeRoute" mode="out-in">
                    <router-view :sessao="sessaoAtual" :telao-settings="telaoSettingsFake" :exibir-apenas-apelido-vereador="isExibirApenasApelidoVereador" />
                </transition>
            </div>
            <div v-else class="vertically-centered">
                <important-message-card>
                    <span v-show="!existeSessaoAtual && isLoadingProximaSessao">Buscando próxima sessão...</span>
                    <span v-show="!isLoadingProximaSessao && !isEncerradaRecente">{{ textoProximaSessao }}</span>
                    <span v-show="existeSessaoAtual && isEncerradaRecente">A {{ tituloSessao }} foi encerrada!</span>
                </important-message-card>
            </div>
        </div>
    </section>
</template>

<script>
import SessaoServiceTelao from './domain/SessaoServiceTelao';
import BarraTopo from './components/shared/BarraTopo.vue';
import ImportantMessageCard from './components/shared/ImportantMessageCard.vue';
import AcompanhamentoWebSocketService from './domain/AcompanhamentoWebSocketService.js';

export default {
    components: {
        'barra-topo': BarraTopo,
        'important-message-card': ImportantMessageCard
    },

    data() {
        return {
            sessaoServiceTelao: new SessaoServiceTelao(this.$dominio),
            brasao: '',
            nomeCliente: '',
            tituloSessao: '',
            telaoSettings: null,
            isExibirApenasApelidoVereador: false,
            sessaoAtual: null,
            sessaoProxima: null,
            isLoadingProximaSessao: true,
            isEncerradaRecente: false,
            acompanhamentoSocket: null,

            telaoSettingsFake: {
                painelEletronico: {
                    font_size_titulo: 1,
                    font_size_titulo_proposicao: 1,
                },
                presentes: {
                    font_size_titulo: 1,
                    font_size_nome_vereador: 1,
                },
                comPalavra: {
                    font_size_nome_vereador: 1,
                    font_size_cronometro: 1,
                    size_foto_vereador: 1,
                },
            }
        };
    },

    computed: {
        existeSessaoAtual() {
            return typeof this.sessaoAtual === 'object' && this.sessaoAtual !== null;
        },
        textoProximaSessao() {
            if (typeof this.sessaoProxima === 'object' && this.sessaoProxima !== null) {
                return `A próxima sessão deve ocorrer em ${this.sessaoProxima.data} às ${this.sessaoProxima.hora}`;
            }
            return 'Não há sessão legislativa agendada para os próximos dias.';
        },
        currentRouteName() {
            return this.$route.name || '';
        },
        isStandByMode() {
            return !(typeof this.sessaoAtual === 'object' && this.sessaoAtual !== null) || this.isEncerradaRecente;
        }
    },

    methods: {
        initialUpdateDadosBasicos() {
            this.sessaoServiceTelao
                .getDadosBasicos()
                .then(response => {
                    this.brasao = response.data.data.links.brasao_url;
                    this.nomeCliente = response.data.data.nome;
                })
                .catch(error => {
                    console.error('Erro ao obter dados básicos do cliente.', error);
                });
        },
        initialUpdateTelaoSettings() {
            this.sessaoServiceTelao
                .getTelaoSettings()
                .then(response => {
                    this.telaoSettings = response.data.data.telaoSettings;
                    const adminSettings = response.data.data.settings;
                    this.isExibirApenasApelidoVereador = (adminSettings.telao_exibir_apenas_apelido_vereador === true);
                })
                .catch(error => {
                    console.error('Erro ao obter dados de telão settings.', error);
                })
                .then(() => {
                    let channelName = `portyx_${this.$dominio}_telao_settings`;
                    let eventName = '.App\\Events\\TelaoSettingsUpdated';
                    window.Echo.channel(channelName).listen(eventName, socketData => {
                        this.telaoSettings = socketData.telaoSettings;
                    });
                });
        },
        updateProximaSessao() {
            this.isLoadingProximaSessao = true;
            this.sessaoServiceTelao
                .proximaSessao()
                .then(response => {
                    this.sessaoProxima = response.data.sessao;
                    let channelName = new AcompanhamentoWebSocketService(this.$dominio, this.sessaoProxima.id).getChannelName();
                    let eventName = '.App\\Events\\AcompanhamentoSessao\\SessaoAberta';
                    window.Echo.channel(channelName).listen(eventName, () => {
                        let dominio = this.$dominio;
                        let viewInicial = 'presentes';
                        this.$router.push({ name: viewInicial, params: { dominio } });
                        window.setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                        
                    });
                })
                .catch(error => {
                    console.error('Erro ao obter próxima sessão.', error);
                })
                .then(() => {
                    this.isLoadingProximaSessao = false;
                });
        },
    },

    created() {
        this.initialUpdateDadosBasicos();
        this.initialUpdateTelaoSettings();

        this.sessaoServiceTelao
            .sessaoAbertaAtual()
            .then(response => {
                this.sessaoAtual = response.data.sessao;
                let categoria = this.sessaoAtual.categoria.nome;
                let numero = this.sessaoAtual.numero.padStart(4, '0');
                let ano = this.sessaoAtual.ano.padStart(4, '0');
                this.tituloSessao = `Sessão ${categoria} ${numero}/${ano}`;
                this.isLoadingProximaSessao = false;
                let eventPrefix = '.App\\Events\\AcompanhamentoSessao\\';
                let channelName = new AcompanhamentoWebSocketService(this.$dominio, this.sessaoAtual.id).getChannelName();
                window.Echo.channel(channelName).listen(eventPrefix + 'SessaoUpdated', () => {
                    window.location.reload();
                });
                window.Echo.channel(channelName).listen(eventPrefix + 'ViewTelaoMudou', socketData => {
                    let dominio = this.$route.params.dominio;
                    let nextRoute = socketData.targetViewName;
                    if (this.$route.name !== nextRoute) {
                        this.$router.push({ name: nextRoute, params: { dominio } });
                    }
                });
                window.Echo.channel(channelName).listen(eventPrefix + 'SessaoEncerrada', () => {
                    this.isEncerradaRecente = true;
                    this.updateProximaSessao();
                });
            })
            .catch(error => {
                if (error.response.status == 404) {
                    this.updateProximaSessao();
                }
                if (error.response.status >= 500) {
                    console.error('Falha na comunicação com o servidor enquanto recuperava a Sessão atual.');
                }
            });
    },

};
</script>

<style lang="scss">
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Share', sans-serif;
    font-weight: 400;
}

@keyframes bg-scrolling {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 200px 200px;
    }
}

.fadeRoute {
    &-enter-active,
    &-leave-active {
        transition: 0.5s ease-in-out;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }

    &-enter-to,
    &-leave {
        opacity: 1;
    }
}

.main-section {
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
    background: #041f35;
}

.main-section .conteudo {
    flex-grow: 1;
    padding: 1em;
    position: relative;
}

.main-section .conteudo .caixa-view {
    position: absolute;
    left: 1em;
    right: 1em;
    top: 1em;
    bottom: 1em;
}

.main-section .conteudo.standby {
    background-image: url('./assets/img/pngs/legislarr-pattern-white.png');
    background-size: 200px;
    animation: bg-scrolling 6s infinite linear;
}

.main-section .conteudo .vertically-centered {
    position: absolute;
    left: 1em;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}
</style>
