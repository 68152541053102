import axios from 'axios';

const http = axios.create({
    baseURL: process.env.VUE_APP_API_HOST + '/api/telao/v1',
    timeout: 30000,
    headers: { 'Content-Type': 'application/json' }
});

http.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

http.interceptors.response.use(
    response => {
        return response;
    },
    function(error) {
        return Promise.reject(error);
    }
);

export default http;
