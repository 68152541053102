<template>
  <img v-show="isVisible" src="../../assets/img/loading/spin-black.svg" alt="Carregando...">
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    }
  }
}
</script>