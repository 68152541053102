import Echo from 'laravel-echo';

const SOCKETIO_ECHO_CLIENT = new Echo({
    broadcaster: 'socket.io',
    host: `${process.env.VUE_APP_SOCKET_IO_HOST}:${process.env.VUE_APP_SOCKET_IO_PORT}`
});

export default class AcompanhamentoWebSocketService {
    constructor(dominio, sessaoId) {
        this.dominio = dominio;
        this.sessaoId = sessaoId;
        this.namespacePrefix = '.App\\Events\\AcompanhamentoSessao\\';
        this.validEventNames = [
            'PalavraConcedida',
            'PalavraIniciada',
            'PalavraInterrompida',
            'SessaoAberta',
            'SessaoEncerrada',
            'SessaoUpdated',
            'VereadorMarcouPresenca',
            'VereadorVotou',
            'ViewTelaoMudou',
            'VotacaoDeProposicaoEncerrada',
            'VotacaoDeProposicaoIniciada'
        ];
        this.channel = null;
    }

    /**
     * Retorna nome do canal de websocket
     * @returns String
     */
    getChannelName() {
        return `portyx_${this.dominio}_sessao${this.sessaoId}_acompanhamento`;
    }

    /**
     * Retorna um canal de websocket
     * @returns Object
     */
    getChannel() {
        if (this.channel === null) {
            this.channel = SOCKETIO_ECHO_CLIENT.channel(this.getChannelName);
        }
        return this.channel;
    }

    /**
     * Define função a ser invocada quando ocorrer certo evento de websocket.
     * Retorna TRUE se a função foi definida, FALSE se houve erro.
     * @param String eventName 
     * @param Function callbackFunction 
     * @returns Boolean
     */
    setEventCallback(eventName, callbackFunction) {
        if (!this.validEventNames.includes(eventName)) {
            console.error('Tentou definir callback para um evento inexistente.');
            return false;
        }
        if (!(callbackFunction instanceof Function)) {
            console.error('Tentou definir callback sem uma função válida.');
            return false;
        }
        let eventFullName = `${this.namespacePrefix}${eventName}`;
        SOCKETIO_ECHO_CLIENT
        .channel(this.getChannelName)
        .listen(eventFullName, payload => {
            console.log(`DEBUG: Evento websocket de ${eventName} recebido!`);
            callbackFunction(payload);
        });
        return true;
    }
}
