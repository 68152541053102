export const routes = [
    {
        path: '/:dominio',
        name: 'dominioSemRota',
        component: () => import('../views/PainelEletronico.vue')
    },
    {
        path: '/:dominio/painelEletronico',
        name: 'painelEletronico',
        component: () => import('../views/PainelEletronico.vue')
    },
    {
        path: '/:dominio/votacao',
        name: 'votacao',
        component: () => import('../views/Votacao.vue')
    },
    {
        path: '/:dominio/comPalavra',
        name: 'comPalavra',
        component: () => import('../views/ComPalavra.vue')
    },
    {
        path: '/:dominio/presentes',
        name: 'presentes',
        component: () => import('../views/Presentes.vue')
    },
];
