import './assets/css/template.css';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Echo from 'laravel-echo';

Vue.config.productionTip = false;

window.io = require('socket.io-client');

window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: `${process.env.VUE_APP_SOCKET_IO_HOST}:${process.env.VUE_APP_SOCKET_IO_PORT}`,
});

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
